// Функция отправки формы
formSubmit = function (event) {
	event.preventDefault();

	let _this = $(this)
	let form = event.target
	// let formID = $(this).attr('id');
	let formID = form.id
	let formNm = $('#' + formID) // обращение к форме через jQuery

	// let form = document.getElementById(formID) // pureJS

	let formWrapper = form.closest(".js-form-wrapper")
	let successMessage = formWrapper.querySelector('.js-form-success')
	let errorsArea = formWrapper.querySelector('.js-form-errors')

	formNm.addClass('inloading')

	// заполняем текущую страницу
	if (form.elements["source_url"]) {
		form.elements["source_url"].value = document.URL
		source_url = form.elements["source_url"].value
		console.log('[INFO] source_url', source_url);
	}

	// получаем значение опции subject
	let subject = null
	if (form.elements["subject"]) {
		subject = form.elements["subject"].value
		console.log('[INFO] subject', subject);
	}


	// получаем значение опции редиректа
	let redirect = false
	if (form.elements["redirect"]) {
		redirect = form.elements["redirect"].value
		console.log('[INFO] redirect', redirect);
	}


	// получаем значение опции яндекс цели
	let ym_id = false
	let ym_target = false
	if (form.elements["ym_id"]) {
		ym_id = form.elements["ym_id"].value // ID счетчика
		ym_target = form.elements["ym_target"].value // Название цели
	}
	console.log('[INFO] ym_id', ym_id);

	// Сериализуем поля
	// let inputs = document.querySelectorAll('#' + formID + ' [data-label]')
	let inputs = formNm.find('[data-label]')
	let serializeObj = {}
	let serializeArr = []
	let i = 0
	for (let input of inputs) {
		let label = input.dataset.label
		let value = input.value

		item = {
			'label': label,
			'value': value
		}

		// serializeArr.push(item)
		// serializeObj.push(item)
		serializeObj[i] = item;
		i = i + 1

		// if (obj[key] !== undefined) {
		//   if (!Array.isArray(obj[key])) {
		//     obj[key] = [obj[key]];
		//   }
		//   obj[key].push(value);
		// } else {
		//   obj[key] = value;
		// }
	}

	console.log('[INFO] serializeObj', serializeObj)

	// Convert Form To JSON
	function convertFormToJSON(form) {
		const array = $(form)
			.serializeArray(); // Encodes the set of form elements as an array of names and values.
		const json = {};
		$.each(array, function () {
			json[this.name] = this.value || "";
		});
		return json;
	}

	// Формируем сообщение
	let mail = '';
	mail += '<h2>Заявка с сайта</h2><br><br>';

	for (const [index, item] of Object.entries(serializeObj)) {
		let label = item.label
		let value = item.value
		console.log(`${label}: ${value}`);
		mail += '<b>' + label + ":</b> " + value + '<br>';
	}

	// Show an element
	var show = function (elem) {
		elem.style.display = 'block';
	};

	// Hide an element
	var hide = function (elem) {
		elem.style.display = 'none';
	};

	// Toggle element visibility
	var toggle = function (elem) {
		// If the element is visible, hide it
		if (window.getComputedStyle(elem).display === 'block') {
			hide(elem);
			return;
		}

		// Otherwise, show it
		show(elem);
	};

	function showSuccessMessage() {
		show(successMessage)
		hide(form)
		// _this.html(data); // выводим в форму сообщение
	}

	// показываем ошибки
	function showErrors(error) {
		// errorsArea.html('Ошибка отправки формы', error)
		console.log(error)
		show(errorsArea)
		hide(form)
	}

	// закрываем окно
	function closeDialog() {
		Fancybox.close()
	}

	// возвращаем спрятанную форму обратно
	function returnForm() {
		hide(successMessage)
		hide(errorsArea)
		show(form)
	}

	$.ajax({
		type: "POST",
		url: '/php/sender.php',
		data: {
			subject: subject,
			mail: mail
		},

		success: function (data) {
			formNm.removeClass('inloading');

			// Отмечаем событие в метрике
			if (ym_id) {
				// console.log(`[INFO] ym(${ym_id}, 'reachGoal', '${ym_target}')`);
				eval(`ym(${ym_id}, 'reachGoal', '${ym_target}')`);
			}

			console.log('[INFO] Форма успешно оправлена');

			if (redirect) {
				window.location.replace(redirect); // редирект на страницу
			} else {
				// _this.html(data); // выводим в форму сообщение
				showSuccessMessage() // показываем сообщение об успешной отправке
				setTimeout(closeDialog, 4000) // закрываем окно
				setTimeout(returnForm, 5000) // возвращаем отображение формы

			}
		},

		error: function (jqXHR, text, error) {
			formNm.removeClass('inloading');
			showErrors(error)
			// _this.html('Ошибка отправки формы');
			// console.log('[INFO] Ошибка отправки формы', error);
		}
	});

	return false;
};

// Маски полей ввода
formMaskInputs = function () {
	// Маска полей ввода
	const phoneInputs = document.getElementsByClassName('input_phone');
	const phoneMaskOptions = {
		mask: '+{7} (000) 000-00-00'
	};

	Array.from(phoneInputs).forEach((input) => {
		// console.log(el.tagName);
		IMask(input, phoneMaskOptions);
	});

}

// Валидация форм
formValidate = function () {
	const validation = new window.JustValidate('.js-form', {
		validateBeforeSubmitting: true,
	});

	validation
		// Имя
		.addField(document.querySelector('.input_name'), [
			{
				rule: 'required',
				errorMessage: 'Поле "Имя" обязательное',
			},
			{
				rule: 'minLength',
				value: 2,
				errorMessage: 'Имя слишком короткое',
			},
		])

		// Телефон
		.addField(document.querySelector('.input_phone'), [
			{
				rule: 'required',
				errorMessage: 'Поле "Телефон" обязательное',
			},
			{
				rule: 'minLength',
				value: 18,
				errorMessage: 'Телефон слишком короткий',
			},
			// {
			// 	validator: function (name, value) {
			// 		const phone = selector.inputmask.unmaskedvalue()
			// 		return Number(phone) && phone.length === 10
			// 	},
			// 	errorMessage: 'Номер должен содержать 10 цифр',
			// }
		])

		.onSuccess((event) => {
			console.log('SUCCESS', event)

			// Сюда вставляем JS обработчик формы
			formSubmit(event);
			// document.getElementById("app-form").submit();
		});
}

document.addEventListener('DOMContentLoaded', function () {
	// document.getElementById('formid').addEventListener('submit', function (e) {
	// 	e.preventDefault();
	// });

	formMaskInputs(); // Маски полей
	formValidate(); // Валидация форм

	const forms = document.querySelectorAll(".js-form");

	forms.forEach((form) => {
		// Для каждой формы e.preventDefault()
		form.addEventListener('submit', function (e) {
			e.preventDefault();
		})
	});
});