// // window.addEventListener('scroll', function () {
// // 	console.log('scrollY = ' + window.scrollY);
// // })

// window.onload = function () {
// 	// lax.init()

// 	// Add a driver that we use to control our animations
// 	lax.addDriver('scrollY2', function () {
// 		return window.scrollY
// 	})

// 	lax.addElements('#sticky-prices-header', {
// 		scrollY2: {
// 			translateY: [
// 				["elInY", "elOutY"],
// 				[100, 750],
// 			]
// 		},
// 	})
// }